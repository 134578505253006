import React from "react"

import { Link } from "gatsby-plugin-intl"

// Hooks
import useTranslation from "./../hooks/useTranslation"

// Icons
import TwispayLogo from "../assets/icons/twispay-logo.svg"
import TwistedShape from "../assets/footer-twisted-shape.svg"

// Styles
import "../styles/components/footer.scss"

// Components
import SocialMedia from "../components/social-media"

const Footer = ({ isSimplified, noMarginTopFooter, noHighlightedSection }) => {
  const { t } = useTranslation()
  const gateway = {
    title: "GATEWAy",
    links: [
      { title: "Home", to: "/" },
      { title: t("Product"), to: "/product" },
      // { title: t("Pricing"), to: "/pricing" },
      { title: t("Company"), to: "/about" },
    ],
  }

  return (
    <footer
      style={{ marginTop: noMarginTopFooter ? 0 : 300 }}
      className={`${isSimplified ? "is-simplified" : ""}`}
    >
      <div className="twisted-shapes-wrapper is-hidden-mobile">
        <img src={TwistedShape} alt=" Twisted Shape" />
      </div>
      {!noHighlightedSection && (
        <section
          className={`section upper-section has-text-centered ${
            isSimplified ? "has-margin-bottom-4" : "has-margin-bottom-6"
          } has-margin-bottom-0-mobile`}
        >
          <div className="container">
            <h1
              className={`title is-size-3-mobile ${
                isSimplified ? "" : "has-text-white"
              }  is-spaced has-margin-bottom-3`}
            >
              {t("footer_highlight_text")}
            </h1>
            <h2
              className={`${
                isSimplified ? "" : "has-text-white"
              } subtitle is-size-5-mobile`}
            >
              <a
                className={`${
                  isSimplified ? "has-text-deep-dark-blue" : "has-text-primary"
                }`}
                href="https://merchant-stage.twispay.com/auth/signup"
                rel="noopener noreferrer"
                target="_blank"
              >
                {t("footer_highlight_link1")}
              </a>{" "}
              {t("or")}{" "}
              <Link
                className={`${
                  isSimplified ? "has-text-deep-dark-blue" : "has-text-primary"
                }`}
                to="/contact"
              >
                {t("footer_highlight_link2")}
              </Link>
              .
            </h2>
          </div>
        </section>
      )}
      <section className="section">
        <div className="container">
          {isSimplified ? (
            <>
              <div className="is-flex-tablet is-hcentered has-margin-bottom-5 has-text-centered-mobile is-size-7-mobile">
                <Link
                  className="is-block link-wrapper has-margin-right-5 has-margin-right-0-mobile has-margin-bottom-4"
                  to="/terms-and-conditions"
                >
                  {t("Terms & Conditions")}
                </Link>
                <Link
                  className="is-block link-wrapper has-margin-right-5 has-margin-right-0-mobile has-margin-bottom-4"
                  to="/privacy-and-cookie-policy"
                >
                  {t("Privacy and Cookie Policy")}
                </Link>
                <Link
                  className="is-block link-wrapper"
                  to="/prohibited-product-and-services-list"
                >
                  {t("Prohibited Product and Services")}
                </Link>
              </div>
              <p className="is-size-7 copyrights has-text-centered has-text-secondary">
                © {new Date().getFullYear()} Twispay
              </p>
            </>
          ) : (
            <div className="columns is-centered">
              <div className="column is-9">
                <div className="lower-section">
                  <div>
                    <h6 className="title is-7">{gateway.title}</h6>
                    {gateway.links.map((link, i) => (
                      <Link
                        className={`is-block link-wrapper ${i !==
                          gateway.links.length - 1 && "has-margin-bottom-3"}`}
                        to={link.to}
                        key={link.to}
                      >
                        {link.title}
                      </Link>
                    ))}
                  </div>
                  <div>
                    <h6 className="title is-7">{t("Account")}</h6>

                    <a
                      className="is-block link-wrapper has-margin-bottom-3"
                      href="https://merchant-stage.twispay.com/auth/signin"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {t("Log in")}
                    </a>
                    <a
                      className="is-block link-wrapper has-margin-bottom-3"
                      href="https://merchant-stage.twispay.com/auth/signup"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {t("Sign Up")}
                    </a>
                    <Link
                      className="is-block link-wrapper has-margin-bottom-3"
                      to="/terms-and-conditions"
                    >
                      {t("Terms & Conditions")}
                    </Link>
                    <Link
                      className="is-block link-wrapper has-margin-bottom-3"
                      to="/privacy-and-cookie-policy"
                    >
                      {t("Privacy and Cookie Policy")}
                    </Link>
                    <Link
                      className="is-block link-wrapper"
                      to="/prohibited-product-and-services-list"
                    >
                      {t("Prohibited Product and Services")}
                    </Link>
                  </div>
                  <div>
                    <h6 className="title is-7">{t("Contact")} </h6>
                    <p className="link-wrapper has-margin-bottom-3">
                      Capital Financial Services S.A.
                    </p>
                    <p className="link-wrapper has-margin-bottom-3">
                      48 Iancu de Hunedoara
                    </p>
                    <p className="link-wrapper has-margin-bottom-3">
                      Crystal Tower
                    </p>
                    <p className="link-wrapper">Bucharest, Sector 1</p>
                  </div>
                  <div className="is-hidden-mobile">
                    <div className="logo-wrapper has-text-secondary">
                      <TwispayLogo />
                    </div>
                  </div>
                  <div className="is-hidden-mobile">
                    <p className="is-size-7">
                      {" "}
                      © {new Date().getFullYear()} Twispay
                    </p>
                  </div>
                  <SocialMedia className="is-hidden-mobile" />
                  <div className="others-wrapper is-hidden-tablet">
                    <div className="logo-wrapper has-text-secondary">
                      <TwispayLogo />
                    </div>
                    <SocialMedia />
                    <p className="is-size-7">
                      {" "}
                      © {new Date().getFullYear()} Twispay
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </footer>
  )
}

export default Footer
