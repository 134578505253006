import React from "react"

// Icons
import InstagramIcon from "../assets/icons/instagram.svg"
import FacebookIcon from "../assets/icons/facebook.svg"
import TwitterIcon from "../assets/icons/twitter.svg"
import LinkedinLogo from "../assets/icons/linkedin.svg"

const SocialMedia = ({ size, className }) => {
  const platforms = [
    { icon: <InstagramIcon />, link: "//instagram.com/twispay" },
    { icon: <FacebookIcon />, link: "//facebook.com/twispay" },
    { icon: <TwitterIcon />, link: "//twitter.com/twispay" },
    { icon: <LinkedinLogo />, link: "//linkedin.com/company/twispay" },
  ]
  return (
    <div className={`is-flex ${className}`}>
      {platforms.map(platform => (
        <a
          key={platform.link}
          href={platform.link}
          className="icon has-margin-right-4"
          rel="noopener noreferrer"
          target="_blank"
          style={{ color: "inherit", width: size, height: size }}
        >
          {platform.icon}
        </a>
      ))}
    </div>
  )
}

export default SocialMedia
