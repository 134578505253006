import React from "react"
import Helmet from "react-helmet"

function Cookies() {
  return (
    <Helmet>
      <script type="text/javascript">
        {`
            var cpm = {};
            (function(h,u,b){
            var d=h.getElementsByTagName("script")[0],e=h.createElement("script");
            e.async=true;e.src='https://cookiehub.net/c2/45357d60.js';
            e.onload=function(){u.cookiehub.load(b);}
            d.parentNode.insertBefore(e,d);
            })(document,window,cpm);
          `}
      </script>
    </Helmet>
  )
}

export default Cookies
